export enum ThumbnailImageVariant {
  MAIN = 'main',
  PREVIEW = 'preview',
}

export interface ThumbnailProps {
  images: CommonTypes.ImageType[];
  hasZoom?: boolean;
  className?: string;
}
