import React, { FC } from 'react';

import { Container, Element } from 'layout';
import Typography from 'common/Typography';
import Card, { CARD_VARIANTS } from 'components/Card';

import { RELATED_PRODUCTS_VARIANTS, RelatedProductsProps } from './models.d';

import './RelatedProducts.scss';

const { LISTING: RELATED_PRODUCTS_LISTING } = RELATED_PRODUCTS_VARIANTS;
const { REGULAR, LISTING } = CARD_VARIANTS;

const RelatedProducts: FC<RelatedProductsProps> = ({ title, ctaText, products, variant }) => {
  const renderTitle = (): JSX.Element | null => {
    if (!title) return null;

    return (
      <Typography
        as="h2"
        size={{ base: 30, xl: 50 }}
        align="center"
        className="related-products__title"
        padding={{ bottom: { base: 'md', md: 'lg' } }}
      >
        {title}
      </Typography>
    );
  };

  const renderProducts: JSX.Element[] = products.map((product) => {
    const { productTitle, productSubtitle, productImage, url, id } = product;
    const buttonProps = {
      text: ctaText,
      ariaLabel: `${ctaText} ${productTitle} ${productSubtitle}`,
      url: {
        name: `${productTitle} ${productSubtitle}`,
        url,
      },
    };

    return (
      <Card
        className="related-products__item"
        productTitle={productTitle}
        productSubtitle={productSubtitle}
        button={buttonProps}
        productImage={productImage}
        variant={variant === RELATED_PRODUCTS_LISTING ? LISTING : REGULAR}
        key={id}
      />
    );
  });

  return (
    <Container gutter={false} className="related-products" data-testid="related-products">
      {renderTitle()}
      <Element className="related-products__items" element="div">
        {renderProducts}
      </Element>
    </Container>
  );
};

export default RelatedProducts;
