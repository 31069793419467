import React, { FC } from 'react';

import Typography, { TypographyProps } from 'common/Typography';
import Accordion, { AccordionVariant } from 'components/Accordion';

import { ProductFeaturesProps } from './models.d';

import './ProductFeatures.scss';

const { TERTIARY } = AccordionVariant;
const PREFIX = '•';

const ProductFeatures: FC<ProductFeaturesProps> = ({
  showLessText,
  showMoreText,
  title,
  items,
}) => {
  const accordionButton = { openText: showMoreText, closedText: showLessText };
  const visibleItemsCount = Math.round(items.length / 2);

  const listPrefixProps: TypographyProps = {
    size: { base: 18, md: 16 },
    padding: { right: 'xxs' },
  };

  const listItemProps: TypographyProps = {
    size: { base: 18, md: 16 },
    padding: { bottom: 'xxs' },
  };

  const visibleContent = (): JSX.Element | null => {
    if (!items) return null;

    const renderContent = items?.slice(0, visibleItemsCount).map(({ value }) => (
      <li key={value} className="product-features__list-item">
        <Typography {...listPrefixProps}>{PREFIX}</Typography>
        <Typography {...listItemProps}>{value}</Typography>
      </li>
    ));

    return (
      <ul className="product-features__list">
        <li>
          <Typography
            key={title}
            size={{ base: 18, md: 16 }}
            padding={{ bottom: 'sm' }}
            weight="bold"
          >
            {title}
          </Typography>
        </li>
        {renderContent}
      </ul>
    );
  };

  const hiddenContent = (): JSX.Element | null => {
    if (!items) return null;

    const renderContent = items?.slice(visibleItemsCount, -1).map(({ value }) => (
      <li key={value} className="product-features__list-item">
        <Typography {...listPrefixProps}>{PREFIX}</Typography>
        <Typography {...listItemProps}>{value}</Typography>
      </li>
    ));

    return (
      <ul className="product-features__list">
        {renderContent}
        <li>
          <Typography
            key={items[items.length - 1].value}
            size={{ base: 18, md: 16 }}
            padding={{ top: 'md' }}
            weight="bold"
          >
            {items[items.length - 1].value}
          </Typography>
        </li>
      </ul>
    );
  };

  return (
    <Accordion
      className="product-features"
      variant={TERTIARY}
      button={accordionButton}
      visibleContent={visibleContent()}
      hiddenContent={hiddenContent()}
    />
  );
};

export default ProductFeatures;
