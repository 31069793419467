import React, { FC } from 'react';

import Typography from 'common/Typography';
import Accordion, { AccordionVariant } from 'components/Accordion';
import Button, { ButtonProps } from 'components/Button';
import ProductFeatures from 'components/ProductFeatures';
import Thumbnail from 'components/Thumbnail';

import { ProductDetailsSectionProps } from './models.d';

import './ProductDetailsSection.scss';

const { PRIMARY } = AccordionVariant;

const ProductDetailsSection: FC<ProductDetailsSectionProps> = ({
  productTitle,
  productSubtitle,
  productDescription,
  productImage,
  buyNowButton,
  productFeatures,
  productSectionDescription,
  priceSpiderSku,
}) => {
  const {
    text,
    ariaLabel,
    url: { url },
  } = buyNowButton;

  const buttonProps: ButtonProps = {
    url,
    ariaLabel,
    variant: 'solid',
    color: 'dark-green',
    weight: 'bold',
    size: { base: 24, md: 28 },
    padding: {
      top: 'xs',
      right: 'xxxl',
      bottom: 'xs',
      left: 'xxxl',
    },
    className: 'product-details-section__button',
  };

  const renderDescription = (): JSX.Element[] | null => {
    if (!productSectionDescription) return null;

    return productSectionDescription.map(({ title, description }): JSX.Element => {
      const visibleContent = (
        <Typography size={24} weight="bold">
          {title}
        </Typography>
      );

      const hiddenContent = (
        <Typography
          dangerouslySetInnerHTML={description}
          size={{ base: 18, md: 14 }}
          padding={{ top: 'md' }}
        />
      );

      return (
        <Accordion
          key={title}
          className="product-details-section__accordion"
          variant={PRIMARY}
          iconSize="big"
          visibleContent={visibleContent}
          hiddenContent={hiddenContent}
        />
      );
    });
  };

  const renderProductDescription = (): JSX.Element | null => {
    if (!productDescription) return null;

    return <Typography size={16}>{productDescription}</Typography>;
  };

  const renderButton = (): JSX.Element | null => {
    if (!url || url === '#') return null;

    return <Button {...buttonProps}>{text}</Button>;
  };

  return (
    <div className="product-details-section" data-testid="product-details-section-item">
      <div className="product-details-section__image">
        <Thumbnail images={[productImage]} />
        {priceSpiderSku ? <div className="ps-widget" ps-sku={priceSpiderSku} /> : null}
      </div>
      <div className="product-details-section__description">
        <Typography
          size={{ base: 30, md: 35 }}
          padding={{ bottom: 'xs' }}
          className="product-details-section__title"
          as="h1"
        >
          {productTitle}
        </Typography>
        <Typography size={{ base: 20, md: 25 }} padding={{ bottom: { base: 'sm', md: 'md' } }}>
          {productSubtitle}
        </Typography>
        {renderProductDescription()}
        {renderButton()}
        <ProductFeatures {...productFeatures} />
        {renderDescription()}
      </div>
    </div>
  );
};

export default ProductDetailsSection;
