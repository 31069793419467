import React, { FC, useRef, useState } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';
import Button from 'components/Button';
import Modal, { ModalSetters } from 'containers/Modal';

import { ThumbnailImageVariant, ThumbnailProps } from './models.d';

import './Thumbnail.scss';

const IMAGES_COUNT = 3;
const { MAIN, PREVIEW } = ThumbnailImageVariant;

const Thumbnail: FC<ThumbnailProps> = ({ images, hasZoom, className }) => {
  const imagesArray = images.slice(0, IMAGES_COUNT);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState<number>(0);
  const modal = useRef<ModalSetters>(null);

  const thumbnailClasses = classNames('thumbnail', className);
  const thumbnailPreviewClasses = (index: number) =>
    classNames('thumbnail__preview', 'background--white', {
      'thumbnail--active': index === activeImageIndex,
    });
  const thumbnailImageClasses = (index: number) =>
    classNames('thumbnail__image', {
      'thumbnail--active': index === activeImageIndex,
      'thumbnail--zoom': hasZoom,
    });

  const renderImages = (variant: ThumbnailImageVariant) => {
    if (!images?.length) return null;

    const renderImage = (image: CommonTypes.ImageType, index: number) => {
      const onMainClick = () => {
        if (modal.current) modal.current.open();
        setFullscreenImageIndex(index);
      };

      const buttonProps = () => {
        switch (variant) {
          case MAIN:
            return {
              className: thumbnailImageClasses(index),
              onClick: hasZoom ? onMainClick : undefined,
            };

          case PREVIEW:
            return {
              className: thumbnailPreviewClasses(index),
              onClick: () => {
                setActiveImageIndex(index);
              },
            };

          default:
            return null;
        }
      };

      return (
        <Button variant="simple" {...buttonProps()} key={index}>
          <Image
            {...image}
            objectFit="contain"
            className="thumbnail__image-content"
            data-testid="thumbnail-image-item"
          />
        </Button>
      );
    };

    return imagesArray.map((image, index: number) => renderImage(image, index));
  };

  return (
    <div className={thumbnailClasses} data-testid="thumbnail-item">
      <div className="thumbnail__main">{renderImages(MAIN)}</div>
      {renderImages(PREVIEW)}
      <Modal ref={modal}>
        <Image
          {...images[fullscreenImageIndex]}
          objectFit="contain"
          className="thumbnail__image-content"
        />
      </Modal>
    </div>
  );
};

export default Thumbnail;
