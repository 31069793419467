import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ProductDetailsSection from 'containers/ProductDetailsSection';
import RelatedProducts from 'containers/RelatedProducts';

const ProductDetailsPage: FC<ProductDetailPageTypes.ProductDetailProps> = ({
  data: {
    productDetail: {
      urls,
      seo,
      productTitle,
      productSubtitle,
      productDescription,
      productImage,
      buyNowButton,
      productFeatures,
      productSectionDescription,
      relatedProducts,
      priceSpiderSku,
    },
    allProductDetail: { nodes: relatedProductsNodes },
  },
}) => {
  const { title, keywords, description } = seo;

  const productDetailsProps = {
    productTitle,
    productSubtitle,
    productDescription,
    productImage,
    buyNowButton,
    productFeatures,
    productSectionDescription,
    priceSpiderSku,
  };

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Container fluid>
        <Container gutter={false}>
          <ProductDetailsSection {...productDetailsProps} />
          <RelatedProducts {...relatedProducts} products={relatedProductsNodes} />
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $relatedProductsLinks: [String]) {
    productDetail(lang: { eq: $lang }, url: { in: $path }) {
      ...ProductDetailPageFragment
    }
    allProductDetail(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        ...ProductDetailPageFragment
      }
    }
  }
`;

export default ProductDetailsPage;
