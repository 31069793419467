export enum RELATED_PRODUCTS_VARIANTS {
  REGULAR = 'regular',
  LISTING = 'listing',
}

export interface RelatedProductsProps
  extends Partial<Pick<CommonTypes.RelatedProductsType, 'title'>>,
    Pick<CommonTypes.RelatedProductsType, 'ctaText'> {
  products: ProductDetailPageTypes.ProductDetailNodes[];
  variant?: RELATED_PRODUCTS_VARIANTS;
}
